<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <v-row dense>
        <h3 class="title text-center w-100">
          Hemen mobil uygulamamızı indirin ve Robosay sistemini mobil
          cihazınızda hızlı ve güvenli kullanın!
        </h3>
        <v-col lg="6" cols="12" class="text-right">
          <a
            href="https://apps.apple.com/tr/app/robosay/id6446150573"
            target="_blank"
          >
            <img src="media/logos/appstore.svg" alt="Robosay iOS" />
          </a>
        </v-col>
        <v-col lg="6" cols="12" class="text-center text-lg-start">
          <a
            href="https://play.google.com/store/apps/details?id=tr.com.robosay"
            target="_blank"
          >
            <img src="media/logos/googleplay.svg" alt="Robosay Android" />
          </a>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="clusterId && can('see-analyses')">
      <div v-if="!isLoading" id="printMe" style="background-color: white">
        <v-row dense>
          <v-col>
            <h3 class="text-dark">
              <v-icon>mdi-finance</v-icon>
              Mali Durum Analiz
            </h3>
          </v-col>
          <v-col>
            <v-btn
              class="me-2 float-end"
              color="pink"
              outlined
              v-show="printingStatus"
              @click="print()"
              small
              v-print="printObj"
              ref="printBtn"
            >
              Yazdır
            </v-btn>
          </v-col>
        </v-row>
        <hr />
        <v-row dense>
          <v-col sm="12">
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col sm="4">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Banka Hesabı</th>
                            <th>Bakiye</th>
                            <th>Entegrasyon Bakiye</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="account in accounts"
                            :key="account.id"
                            class="bordered"
                          >
                            <td>
                              {{ account.name }}
                            </td>
                            <td>
                              <rs-table-cell-balance
                                colored
                                :value="account.balance"
                              />
                            </td>
                            <td>
                              <rs-table-cell-balance
                                colored
                                :value="account.activity_balance"
                                :title="
                                  new Date(account.activity_on).toLocaleString()
                                "
                                v-if="account.activity_balance !== null"
                              />
                              <span class="text-muted" v-else>Hareket yok</span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="bg-secondary bordered">
                            <td class="text-left bg-secondary">Toplam:</td>
                            <td class="text-left bg-secondary">
                              <rs-table-cell-balance
                                colored
                                :value="accountsum"
                              />
                            </td>
                            <td class="text-left bg-secondary">
                              <rs-table-cell-balance
                                colored
                                :value="accountsumActivities"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col sm="4">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Kasa</th>
                            <th>Bakiye</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="safe in safes"
                            :key="safe.id"
                            class="bordered"
                          >
                            <td>
                              {{ safe.name }}
                            </td>
                            <td>
                              <rs-table-cell-balance
                                colored
                                :value="safe.balance"
                              />
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="bg-secondary bordered">
                            <td class="text-left bg-secondary">Toplam:</td>
                            <td class="text-left bg-secondary">
                              <rs-table-cell-balance colored :value="safesum" />
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col sm="4">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr class="bordered">
                            <td>Banka Gelen Hareket</td>
                            <td class="text-right">{{ bankincome }}</td>
                          </tr>
                          <tr class="bordered">
                            <td>Banka Giden Hareket</td>
                            <td class="text-right">{{ bankexpense }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="bg-secondary bordered">
                            <td class="text-left bg-secondary">Toplam:</td>
                            <td class="text-right bg-secondary">
                              {{ banksum }}
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-4">
              <v-card-text>
                <v-row dense>
                  <v-col sm="6">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th colspan="2">Alacaklar</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="income in incomes"
                            :key="income.id"
                            class="bordered"
                          >
                            <td>
                              {{ income.income_type }}
                            </td>
                            <td class="text-right">
                              <rs-table-cell-number
                                price
                                :value="income.amount"
                              />
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="bg-secondary bordered">
                            <td class="text-left bg-secondary">Toplam:</td>
                            <td class="text-right bg-secondary">
                              <rs-table-cell-number price :value="incomesum" />
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col sm="6">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th colspan="2">Borçlar</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="expense in expenses"
                            :key="expense.id"
                            class="bordered"
                          >
                            <td v-if="expense.diff != 0">
                              {{ expense.expense_type }}
                            </td>
                            <td class="text-right" v-if="expense.diff != 0">
                              <rs-table-cell-number
                                price
                                :value="expense.diff"
                              />
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="bg-secondary bordered">
                            <td class="text-left bg-secondary">Toplam:</td>
                            <td class="text-right bg-secondary">
                              <rs-table-cell-number price :value="expensesum" />
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Bildirim Tedarikçisi</th>
                    <th>Kalan Kredi</th>
                    <th>Son Kredi Kontrolü</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in notificationProviderList" :key="item.id">
                    <td>{{ item.notification_provider_name }}</td>
                    <td class="text-center">
                      <rs-table-cell-number
                        :value="item.remaining_credits"
                        v-if="item.remaining_credits !== null"
                      />
                      <em class="text-muted" v-else>Bilgi yok</em>
                    </td>
                    <td class="text-center">
                      <rs-table-cell-date
                        show-time
                        :value="item.fetched_at"
                        v-if="item.fetched_at !== null"
                      />
                      <em class="text-muted" v-else>Bilgi yok</em>
                      <rs-action
                        icon
                        @click="handleNotificationProviderFetchClick(item)"
                        :is-loading="item.isLoading"
                      >
                        <v-icon>mdi-reload</v-icon>
                      </rs-action>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="6">
            <apexchart
              v-if="seriesIncomeTypeSums.length > 0"
              class="card-rounded-bottom bg-secondary rounded"
              :options="chartOptionsIncomeTypeSums"
              :series="seriesIncomeTypeSums"
              type="pie"
            ></apexchart>
          </v-col>
          <v-col sm="6">
            <apexchart
              v-if="seriesCollectingTypeSums.length > 0"
              class="card-rounded-bottom bg-secondary rounded"
              :options="chartOptionsCollectingTypeSums"
              :series="seriesCollectingTypeSums"
              type="pie"
            ></apexchart>
          </v-col>
        </v-row>
      </div>
      <v-row dense class="mt-3" v-if="hasRole('superadministrator')">
        <v-col>
          <apexchart
            class="card-rounded-bottom bg-secondary rounded"
            :options="chartDailyStats"
            :series="dailyStats"
            type="bar"
          ></apexchart>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-else-if="!can('see-analyses')">
      <h3>Hoş Geldiniz</h3>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { hasForm, hasPermissions } from "@/view/mixins";
import print from "vue-print-nb";

export default {
  name: "Dashboard",
  mixins: [hasForm, hasPermissions],
  directives: { print },
  computed: {
    ...mapGetters(["clusterId", "companyId"]),
    checkPerm() {
      return this.can("see-analyses");
    },
  },
  watch: {
    clusterId() {
      if (this.can("see-analyses")) {
        this.loadList();
      }
    },
    checkPerm(val) {
      if (val) this.loadList();
    },
  },
  data() {
    return {
      datam: null,
      isLoading: false,
      safes: [],
      accounts: [],
      incomes: [],
      expenses: [],
      accountsum: null,
      safesum: null,
      bankincome: null,
      bankexpense: null,
      banksum: null,
      expensesum: null,
      incomesum: null,
      chartOptionsCollectingTypeSums: {},
      chartDailyStats: {},
      dailyStats: [],
      seriesCollectingTypeSums: [],
      chartOptionsIncomeTypeSums: {},
      seriesIncomeTypeSums: [],
      printingStatus: true,
      notificationProviderList: [],
      printObj: {
        id: "printMe",
        popTitle: "Robosay - Mali Durum Analiz",
        closeCallback(vue) {
          vue.printingStatus = true;
        },
      },
    };
  },
  methods: {
    print() {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (isSafari) {
        this.printingStatus = false;
        setTimeout(() => {
          this.printPage("kt_content");
        }, 100);
      } else {
        this.printingStatus = false;
        this.$refs.printBtn.click();
      }
    },
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      this.isLoading = true;

      this.safes = [];
      this.accounts = [];
      this.incomes = [];
      this.expenses = [];
      this.accountsum = null;
      this.safesum = null;
      this.bankincome = null;
      this.bankexpense = null;
      this.banksum = null;
      this.expensesum = null;
      this.incomesum = null;
      this.chartOptionsCollectingTypeSums = {};
      this.seriesCollectingTypeSums = [];
      this.chartOptionsIncomeTypeSums = {};
      this.seriesIncomeTypeSums = [];

      Promise.all([
        this.loadAccounts(),
        this.loadSafes(),
        this.loadBankActivities(),
        this.loadIncomes(),
        this.loadExpenses(),
        this.loadCollectingTypeSums(),
        this.loadIncomeTypeSums(),
        this.getDailyStats(),
        this.loadNotificationProviderList(),
      ]).finally(() => (this.isLoading = false));
    },
    async getDailyStats() {
      if (!this.hasRole("superadministrator")) {
        return;
      }
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - 15));
      const params = {};
      params.cluster_id = this.clusterId;
      params.date = {
        min: priorDate,
      };
      return await this.$api
        .query("admin/daily-stats", { params })
        .then((response) => {
          this.chartDailyStats = {
            title: {
              text: "Günlük Durum",
              align: "left",
              margin: 10,
              offsetX: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: "14px",
                fontWeight: 600,
                color: "#263238",
              },
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: response.data.data.map((x) =>
                new Date(x.date).toLocaleString("tr-TR", {
                  month: "long",
                  year: "numeric",
                  day: "numeric",
                })
              ),
            },
          };
          this.dailyStats = [
            {
              name: "Kullanıcı",
              data: response.data.data.map((x) => x.user_count),
            },
            {
              name: "Sakin",
              data: response.data.data.map((x) => x.resident_count),
            },
            {
              name: "BB",
              data: response.data.data.map((x) => x.house_count),
            },
            {
              name: "TYA",
              data: response.data.data.map((x) => x.cluster_count),
            },
          ];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadAccounts() {
      const params = {};
      params.cluster_id = this.clusterId;

      return await this.$api
        .query("bank-accounts", { params })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.accounts = response.data.data;
            this.accountsum = response.data.meta.totals.balance;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadSafes() {
      const params = {};
      params.cluster_id = this.clusterId;

      params.page = undefined;

      return await this.$api
        .query("safes", { params })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.safes = response.data.data;
            this.safesum = response.data.meta.totals.balance;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadBankActivities() {
      const params = {};
      params.cluster = [this.clusterId];
      params.list_type = "open";
      params.company_id = this.companyId ?? undefined;
      params.order_by = "activity_on";
      params.page = undefined;

      return await this.$api
        .query("income/bank-activities", { params })
        .then((response) => {
          if (response.data && response.data.meta) {
            this.bankincome = response.data.meta.totals.positive_count;
            this.bankexpense = response.data.meta.totals.negative_count;
            this.banksum = response.data.meta.totals.item_count;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadIncomes() {
      const params = {};
      params.cluster_id = this.clusterId;

      params.page = undefined;

      return await this.$api
        .query(`clusters/${this.clusterId}/income-sums`, { params })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.incomes = response.data.data;
            this.incomesum = response.data.meta.totals.amount;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadExpenses() {
      const params = {};
      params.cluster_id = this.clusterId;

      params.page = undefined;

      return await this.$api
        .query(`clusters/${this.clusterId}/expense-sums`, { params })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.expenses = response.data.data;
            this.expensesum = response.data.meta.totals.diff;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getColletings(data) {
      let othersum = 0;
      const sortedata = data.sort((a, b) => {
        return b.monthly_amount - a.monthly_amount;
      });
      const slicedata = sortedata.slice(0, 10);
      const otherdata = sortedata.slice(10, sortedata.length);
      otherdata.forEach((element) => {
        othersum = othersum + element.monthly_amount;
      });
      const all = slicedata.map(
        (x) => x.expense_type + " - " + x.monthly_amount + "₺"
      );
      if (othersum > 0) all.push("Diğer - " + othersum + "₺");
      return all;
    },
    getColletingsAmounts(data) {
      let othersum = 0;
      const sortedata = data.sort((a, b) => {
        return b.monthly_amount - a.monthly_amount;
      });
      const slicedata = sortedata.slice(0, 10);
      const otherdata = sortedata.slice(10, sortedata.length);
      otherdata.forEach((element) => {
        othersum = othersum + element.monthly_amount;
      });
      const all = slicedata.map((x) => x.monthly_amount);
      if (othersum > 0) all.push(othersum);
      return all;
    },
    async loadCollectingTypeSums() {
      const params = {};
      params.cluster_id = this.clusterId;
      params.billed_on = {
        min: new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
        ),
        max: new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0)
        ),
      };
      params.page = undefined;

      return await this.$api
        .query(`expense-analysis/invoice/${this.clusterId}`, {
          params,
        })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.chartOptionsCollectingTypeSums = {
              labels: this.getColletings(response.data.data),
              title: {
                text: "Gider Türleri",
                align: "left",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#263238",
                },
              },
              subtitle: {
                text: new Date().toLocaleString("tr-TR", {
                  month: "long",
                  year: "numeric",
                }),
                floating: false,
                style: {
                  fontSize: "12px",
                  color: "#263238",
                },
              },
            };
            this.seriesCollectingTypeSums = this.getColletingsAmounts(
              response.data.data
            );
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadIncomeTypeSums() {
      const params = {};
      params.cluster_id = this.clusterId;
      params.issues_on = {
        min: new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
        ),
        max: new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0)
        ),
      };
      params.page = undefined;

      return await this.$api
        .query(`income-analysis/income-type-sums/${this.clusterId}`, { params })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.chartOptionsIncomeTypeSums = {
              labels: response.data.data.map(
                (x) => x.name + " - " + x.amount + "₺"
              ),
              title: {
                text: "Tahakkuk Türleri",
                align: "left",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#263238",
                },
              },
              subtitle: {
                text: new Date().toLocaleString("tr-TR", {
                  month: "long",
                  year: "numeric",
                }),
                floating: false,
                style: {
                  fontSize: "12px",
                  color: "#263238",
                },
              },
            };
            this.seriesIncomeTypeSums = response.data.data.map((x) => x.amount);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    async loadNotificationProviderList() {
      if (!this.clusterId) {
        return false;
      }

      const params = {
        cluster_id: this.clusterId,
      };

      this.notificationProviderList = [];
      this.isLoading = true;

      return await this.$api
        .query("cluster-notification-providers", { params })
        .then((response) => {
          this.notificationProviderList = response.data.data
            .map((v) => {
              v.isLoading = false;
              return v;
            })
            .filter((v) => v.is_active && v.is_default);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleNotificationProviderFetchClick(item) {
      if (this.isLoading || !item || item.isLoading) {
        return false;
      }

      item.isLoading = true;

      this.$api
        .get(`cluster-notification-providers/${item.id}/credits`)
        .then((response) => {
          item.remaining_credits = response.data.data.credits;
          item.fetched_at = new Date();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    printPage(divName) {
      let html = "<html>";
      html += document.getElementById(divName).innerHTML;
      html += "</html>";

      const printWin = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      printWin.document.write(html);
      printWin.document.write(`<style>
      @keyframes opaque {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

@keyframes resizeanim {
  0%,to {
      opacity: 0
  }
}

.apexcharts-canvas {
  position: relative;
  user-select: none
}

.apexcharts-canvas ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px
}

.apexcharts-canvas ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
}

.apexcharts-inner {
  position: relative
}

.apexcharts-text tspan {
  font-family: inherit
}

.legend-mouseover-inactive {
  transition: .15s ease all;
  opacity: .2
}

.apexcharts-series-collapsed {
  opacity: 0
}

.apexcharts-tooltip {
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: .15s ease all
}

.apexcharts-tooltip.apexcharts-active {
  opacity: 1;
  transition: .15s ease all
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #e3e3e3;
  background: rgba(255,255,255,.96)
}

.apexcharts-tooltip.apexcharts-theme-dark {
  color: #fff;
  background: rgba(30,30,30,.8)
}

.apexcharts-tooltip * {
  font-family: inherit
}

.apexcharts-tooltip-title {
  padding: 6px;
  font-size: 15px;
  margin-bottom: 4px
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #eceff1;
  border-bottom: 1px solid #ddd
}

.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  background: rgba(0,0,0,.7);
  border-bottom: 1px solid #333
}

.apexcharts-tooltip-text-goals-value,.apexcharts-tooltip-text-y-value,.apexcharts-tooltip-text-z-value {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600
}

.apexcharts-tooltip-text-goals-label:empty,.apexcharts-tooltip-text-goals-value:empty,.apexcharts-tooltip-text-y-label:empty,.apexcharts-tooltip-text-y-value:empty,.apexcharts-tooltip-text-z-value:empty,.apexcharts-tooltip-title:empty {
  display: none
}

.apexcharts-tooltip-text-goals-label,.apexcharts-tooltip-text-goals-value {
  padding: 6px 0 5px
}

.apexcharts-tooltip-goals-group,.apexcharts-tooltip-text-goals-label,.apexcharts-tooltip-text-goals-value {
  display: flex
}

.apexcharts-tooltip-text-goals-label:not(:empty),.apexcharts-tooltip-text-goals-value:not(:empty) {
  margin-top: -6px
}

.apexcharts-tooltip-marker {
  width: 12px;
  height: 12px;
  position: relative;
  top: 0;
  margin-right: 10px;
  border-radius: 50%
}

.apexcharts-tooltip-series-group {
  padding: 0 10px;
  display: none;
  text-align: left;
  justify-content: left;
  align-items: center
}

.apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
  opacity: 1
}

.apexcharts-tooltip-series-group.apexcharts-active,.apexcharts-tooltip-series-group:last-child {
  padding-bottom: 4px
}

.apexcharts-tooltip-series-group-hidden {
  opacity: 0;
  height: 0;
  line-height: 0;
  padding: 0!important
}

.apexcharts-tooltip-y-group {
  padding: 6px 0 5px
}

.apexcharts-custom-tooltip,.apexcharts-tooltip-box {
  padding: 4px 8px
}

.apexcharts-tooltip-boxPlot {
  display: flex;
  flex-direction: column-reverse
}

.apexcharts-tooltip-box>div {
  margin: 4px 0
}

.apexcharts-tooltip-box span.value {
  font-weight: 700
}

.apexcharts-tooltip-rangebar {
  padding: 5px 8px
}

.apexcharts-tooltip-rangebar .category {
  font-weight: 600;
  color: #777
}

.apexcharts-tooltip-rangebar .series-name {
  font-weight: 700;
  display: block;
  margin-bottom: 5px
}

.apexcharts-xaxistooltip,.apexcharts-yaxistooltip {
  opacity: 0;
  pointer-events: none;
  color: #373d3f;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  background: #eceff1;
  border: 1px solid #90a4ae
}

.apexcharts-xaxistooltip {
  padding: 9px 10px;
  transition: .15s ease all
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
  background: rgba(0,0,0,.7);
  border: 1px solid rgba(0,0,0,.5);
  color: #fff
}

.apexcharts-xaxistooltip:after,.apexcharts-xaxistooltip:before {
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.apexcharts-xaxistooltip:after {
  border-color: transparent;
  border-width: 6px;
  margin-left: -6px
}

.apexcharts-xaxistooltip:before {
  border-color: transparent;
  border-width: 7px;
  margin-left: -7px
}

.apexcharts-xaxistooltip-bottom:after,.apexcharts-xaxistooltip-bottom:before {
  bottom: 100%
}

.apexcharts-xaxistooltip-top:after,.apexcharts-xaxistooltip-top:before {
  top: 100%
}

.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #eceff1
}

.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #90a4ae
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after,.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: rgba(0,0,0,.5)
}

.apexcharts-xaxistooltip-top:after {
  border-top-color: #eceff1
}

.apexcharts-xaxistooltip-top:before {
  border-top-color: #90a4ae
}

.apexcharts-xaxistooltip-top.apexcharts-theme-dark:after,.apexcharts-xaxistooltip-top.apexcharts-theme-dark:before {
  border-top-color: rgba(0,0,0,.5)
}

.apexcharts-xaxistooltip.apexcharts-active {
  opacity: 1;
  transition: .15s ease all
}

.apexcharts-yaxistooltip {
  padding: 4px 10px
}

.apexcharts-yaxistooltip.apexcharts-theme-dark {
  background: rgba(0,0,0,.7);
  border: 1px solid rgba(0,0,0,.5);
  color: #fff
}

.apexcharts-yaxistooltip:after,.apexcharts-yaxistooltip:before {
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.apexcharts-yaxistooltip:after {
  border-color: transparent;
  border-width: 6px;
  margin-top: -6px
}

.apexcharts-yaxistooltip:before {
  border-color: transparent;
  border-width: 7px;
  margin-top: -7px
}

.apexcharts-yaxistooltip-left:after,.apexcharts-yaxistooltip-left:before {
  left: 100%
}

.apexcharts-yaxistooltip-right:after,.apexcharts-yaxistooltip-right:before {
  right: 100%
}

.apexcharts-yaxistooltip-left:after {
  border-left-color: #eceff1
}

.apexcharts-yaxistooltip-left:before {
  border-left-color: #90a4ae
}

.apexcharts-yaxistooltip-left.apexcharts-theme-dark:after,.apexcharts-yaxistooltip-left.apexcharts-theme-dark:before {
  border-left-color: rgba(0,0,0,.5)
}

.apexcharts-yaxistooltip-right:after {
  border-right-color: #eceff1
}

.apexcharts-yaxistooltip-right:before {
  border-right-color: #90a4ae
}

.apexcharts-yaxistooltip-right.apexcharts-theme-dark:after,.apexcharts-yaxistooltip-right.apexcharts-theme-dark:before {
  border-right-color: rgba(0,0,0,.5)
}

.apexcharts-yaxistooltip.apexcharts-active {
  opacity: 1
}

.apexcharts-yaxistooltip-hidden {
  display: none
}

.apexcharts-xcrosshairs,.apexcharts-ycrosshairs {
  pointer-events: none;
  opacity: 0;
  transition: .15s ease all
}

.apexcharts-xcrosshairs.apexcharts-active,.apexcharts-ycrosshairs.apexcharts-active {
  opacity: 1;
  transition: .15s ease all
}

.apexcharts-ycrosshairs-hidden {
  opacity: 0
}

.apexcharts-selection-rect {
  cursor: move
}

.svg_select_boundingRect,.svg_select_points_rot {
  pointer-events: none;
  opacity: 0;
  visibility: hidden
}

.apexcharts-selection-rect+g .svg_select_boundingRect,.apexcharts-selection-rect+g .svg_select_points_rot {
  opacity: 0;
  visibility: hidden
}

.apexcharts-selection-rect+g .svg_select_points_l,.apexcharts-selection-rect+g .svg_select_points_r {
  cursor: ew-resize;
  opacity: 1;
  visibility: visible
}

.svg_select_points {
  fill: #efefef;
  stroke: #333;
  rx: 2
}

.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
  cursor: crosshair
}

.apexcharts-svg.apexcharts-zoomable.hovering-pan {
  cursor: move
}

.apexcharts-menu-icon,.apexcharts-pan-icon,.apexcharts-reset-icon,.apexcharts-selection-icon,.apexcharts-toolbar-custom-icon,.apexcharts-zoom-icon,.apexcharts-zoomin-icon,.apexcharts-zoomout-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center
}

.apexcharts-menu-icon svg,.apexcharts-reset-icon svg,.apexcharts-zoom-icon svg,.apexcharts-zoomin-icon svg,.apexcharts-zoomout-icon svg {
  fill: #6e8192
}

.apexcharts-selection-icon svg {
  fill: #444;
  transform: scale(.76)
}

.apexcharts-theme-dark .apexcharts-menu-icon svg,
.apexcharts-theme-dark .apexcharts-pan-icon svg,
.apexcharts-theme-dark .apexcharts-reset-icon svg,.apexcharts-theme-dark .apexcharts-selection-icon svg,.apexcharts-theme-dark .apexcharts-toolbar-custom-icon svg,.apexcharts-theme-dark .apexcharts-zoom-icon svg,.apexcharts-theme-dark .apexcharts-zoomin-icon svg,.apexcharts-theme-dark .apexcharts-zoomout-icon svg {
  fill: #f3f4f5
}

.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg,.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #008ffb
}

.apexcharts-theme-light .apexcharts-menu-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: #333
}

.apexcharts-menu-icon,.apexcharts-selection-icon {
  position: relative
}

.apexcharts-reset-icon {
  margin-left: 5px
}

.apexcharts-menu-icon,.apexcharts-reset-icon,.apexcharts-zoom-icon {
  transform: scale(.85)
}

.apexcharts-zoomin-icon,.apexcharts-zoomout-icon {
  transform: scale(.7)
}

.apexcharts-zoomout-icon {
  margin-right: 3px
}

.apexcharts-pan-icon {
  transform: scale(.62);
  position: relative;
  left: 1px;
  top: 0
}

.apexcharts-pan-icon svg {
  fill: #fff;
  stroke: #6e8192;
  stroke-width: 2
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #008ffb
}

.apexcharts-pan-icon:not(.apexcharts-selected):hover svg {
  stroke: #333
}

.apexcharts-toolbar {
  position: absolute;
  z-index: 11;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.apexcharts-menu {
  background: #fff;
  position: absolute;
  top: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 3px;
  right: 10px;
  opacity: 0;
  min-width: 110px;
  transition: .15s ease all;
  pointer-events: none
}

.apexcharts-menu.apexcharts-menu-open {
  opacity: 1;
  pointer-events: all;
  transition: .15s ease all
}

.apexcharts-menu-item {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: #eee
}

.apexcharts-theme-dark .apexcharts-menu {
  background: rgba(0,0,0,.7);
  color: #fff
}

@media screen and (min-width:768px) {
  .apexcharts-canvas:hover .apexcharts-toolbar {
      opacity: 1
  }
}

.apexcharts-canvas .apexcharts-element-hidden,.apexcharts-datalabel.apexcharts-element-hidden,.apexcharts-hide .apexcharts-series-points {
  opacity: 0
}

.apexcharts-datalabel,.apexcharts-datalabel-label,.apexcharts-datalabel-value,.apexcharts-datalabels,.apexcharts-pie-label {
  cursor: default;
  pointer-events: none
}

.apexcharts-pie-label-delay {
  opacity: 0;
  animation-name: opaque;
  animation-duration: .3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease
}

.apexcharts-annotation-rect,.apexcharts-area-series .apexcharts-area,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-gridline,.apexcharts-line,.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-point-annotation-label,.apexcharts-radar-series path,.apexcharts-radar-series polygon,.apexcharts-toolbar svg,.apexcharts-tooltip .apexcharts-marker,.apexcharts-xaxis-annotation-label,.apexcharts-yaxis-annotation-label,.apexcharts-zoom-rect {
  pointer-events: none
}

.apexcharts-marker {
  transition: .15s ease all
}

.resize-triggers {
  animation: 1ms resizeanim;
  visibility: hidden;
  opacity: 0;
  height: 100%;
  width: 100%;
  overflow: hidden
}

.contract-trigger:before,.resize-triggers,.resize-triggers>div {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0
}

.resize-triggers>div {
  height: 100%;
  width: 100%;
  background: #eee;
  overflow: auto
}

.contract-trigger:before {
  overflow: hidden;
  width: 200%;
  height: 200%
}
      </style>`);
      printWin.document.close();
      printWin.focus();
      printWin.print();
      printWin.close();
      setTimeout(() => {
        this.printingStatus = true;
      }, 100);
    },
  },
  mounted() {
    if (this.can("see-analyses")) {
      this.loadList();
    }
  },
};
</script>
<style lang="scss" scoped>
.flex-column {
  flex-direction: column;
}
.title {
  font-size: 13px !important;
  font-weight: bold !important;
  color: black;
}
tfoot tr > td {
  font-weight: 600 !important;
}
tr.bordered th,
tr.bordered td {
  border: 1px solid #0000001f;
}

tr td {
  font-size: 13px !important;
}

.overlay {
  position: relative;
  text-align: center;
  color: white;
  img {
    opacity: 0.2;
  }
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
  font-weight: bold;
  color: #abcf39;
  background-color: black;
  padding: 6px;
  border-radius: 1em;
  transform: translate(-50%, -50%);
}
</style>
